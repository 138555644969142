import React, { Component } from 'react';
import './style.css';
import { RedocStandalone } from 'redoc';
import $ from "jquery";
import jsonDocData from './index.json';

class DocIndex extends Component {
  state = {
    redirect: false
  }

  render() {

    //in order to remove endpoints and the parent menu item
    //set private true to paths and tags properties in index.json
    //also .env.REACT_APP_FILTER_PRIVATE has to be set in jenkins deployment configs based on env
    //side note: 'components' in index.json is not used due to redoc library not supporting model rendering yet
    function filterJson(obj) {
      for (var k in obj) {
        if (typeof obj[k] == "object" && obj[k] !== null) {
          filterJson(obj[k]);
          [obj[k]].forEach(element => {
            if (element.private === true) {
              if (process.env.REACT_APP_FILTER_PRIVATE === 'true') {
                delete element.$ref;
                if (element.name) {
                  delete element.description;
                  delete element.name;
                }
              }
              delete element.private;
            }
          });
        }
      }
    }

    function filterEmptyTagObject(json) {
      const tags = JSON.stringify(json.tags.filter(function (el) {
        return typeof el === {} || Object.keys(el).length > 0;
      }));

      jsonDocData.tags = JSON.parse(tags);
    }

    filterJson(jsonDocData);
    filterEmptyTagObject(jsonDocData);

    setTimeout(function () {
      $(".sc-hXRMBi").hide();
    }, 1500);
    return (
      <div className="DocIndex">
        <RedocStandalone
          spec={jsonDocData}
          options={{
            requiredPropsFirst: true,
            hideDownloadButton: true,
            menuToggle: true,
            theme: { colors: { main: '#dd5522' } }
          }}
        />
      </div>
    );
  }
}

export default DocIndex;
